<template>
  <v-container fluid>
    <v-row>
      <v-col class="col-12">
        <v-row>
          <v-col class="col-4">
            <h1>Create a new supplier category</h1>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="col-4">
            <v-text-field
              hint="Required"
              persistent-hint
              v-model="supplierCategory.category"
              label="Name"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="col-4">
            <v-btn
              @click="saveSupplierCategory">Save</v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-dialog
      v-model="showMessage"
      max-width="290"
    >
      <v-card>
        <v-card-text class="pt-4">
          {{ message }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="orange darken-2"
            text
            @click="showMessage = false"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from '../../axios';

export default {
  name: 'CreateSupplierCategory',
  data() {
    return {
      supplierCategory: {
        category: '',
      },
      message: '',
      showMessage: false,
    };
  },
  computed: {
    token() {
      return this.$store.state.token;
    },
  },
  methods: {
    saveSupplierCategory() {
      if (
        this.supplierCategory.category.length > 0
        ) {
        axios.post(`/supplierCategories/create.json?token=${this.token}`, this.supplierCategory)
          .then(() => {
            this.message = 'The supplier category has been added.';
            this.showMessage = true;
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.message = 'Please complete required field: Category';
        this.showMessage = true;
      }
    },
  },
}
</script>
